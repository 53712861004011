<template>
  <div class="contact">
    <div class="header-nightsky" style="background:none;box-shadow:inset 0 0 0 1000px #2c4864"> 

    
    <nav class="navbar navbar-expand-lg   static-top">
        <div class="container">    
            <a class="navbar-brand" href="/">
                <img src="logo.png" width="300px">
            </a>
            <button class="navbar-toggler custom-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav ms-auto">
                <li class="nav-item">  
                  <a href="/">Home</a>
                </li>
                <li class="nav-item">
                    <router-link :to="'/contact'">Contatti</router-link>
                </li>
                <li class="nav-item">
                  <router-link :to="'/about'">Chi siamo</router-link>
                </li>
              </ul>
            </div>
        </div>
      </nav>
      <div class="container text-help" style="">
          <h1>Come possiamo aiutarti?</h1>
          <p><span>Inviaci un messaggio!</span></p>
      </div>
    </div>
    <div class="container form">
                
                <div class="booking-form">
                <template v-if="inviato">
                  <span class="msg_inviato" style="color:green;margin-top:10px">
                      Grazie. Il tuo messaggio è stato inviato.
                    </span>
                </template>
                  <form @submit="invia_messaggio"> 
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="form-group">
                                <span class="form-label">Nome</span>
                                <input v-model="contact_form.nome" class="form-control" type="text" required>
                            </div>
                        </div>
                      
                        <div class="col-sm-6">
                            <div class="form-group">
                                <span class="form-label">Cognome</span>
                                <input v-model="contact_form.cognome" class="form-control" type="text" required>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group">
                            <span class="form-label">Email</span>
                            <input v-model="contact_form.email" class="form-control" type="email" required>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group">
                            <span class="form-label">Messaggio</span>
                             <textarea v-model="contact_form.msg" class="form-control" placeholder=" " id="floatingTextarea2" style="height: 100px" required></textarea>
                        </div>
                    </div>
                   <button class="submit-btn">Invia messaggio</button>
                  </form>
                  
                </div>
                 <div class="contact_info">
                    <span style="font-weight:700;">G.F.B. s.r.l.</span>
                    <br>
                    <span>Contrada Costa Morena, 39</span>
                    <br>
                    <span>E-mail: info@globalferry.eu</span>
                </div>
              </div>
  </div>

</template>

<style scoped>
.contact_info{
    color:black;
    margin-top:30px;
    text-align: center;
    font-size: 15px;
}
.text-help{
margin-top:10px;
text-align:center;
color: white;
font-size: 20px;
}
.booking-form{
    background-color: white;
    width: 50%;
    margin-left:25%;
}
@media (max-width: 767px)
    {
        .booking-form{
            width: 100%;
            margin-left: 0px !important;
        }
    }
.form{
    margin-top: 5%;
    margin-bottom: 5%;
}
</style>

<script>

import ServizioApi from '@/servizi/ServizioApi.js'

export default {
    data() {
        return {
            inviato:false,
            contact_form:{
                nome:'',
                cognome:'',
                email:'',
                msg:''
            }
        }
    },
    methods:{
        async invia_messaggio(e)
        {
            e.preventDefault();
            var risp="";

            await ServizioApi.richiestaContatto(this.contact_form)
            .then(risposta=>(risp=risposta.data))
            
            if(risp)
            {
                this.inviato=true;
            }

            
        }
    }
}
</script>