<template>
  <div class="contact">
    <div class="header-nightsky" style="background:none;box-shadow:inset 0 0 0 1000px #2c4864"> 

    
    <nav class="navbar navbar-expand-lg   static-top">
        <div class="container">    
            <a class="navbar-brand" href="/">
                <img src="logo.png" width="300px">
            </a>
            <button class="navbar-toggler custom-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav ms-auto">
                <li class="nav-item">  
                  <a href="/">Home</a>
                </li>
                <li class="nav-item">
                    <router-link :to="'/contact'">Contatti</router-link>
                </li>
                <li class="nav-item">
                  <router-link :to="'/about'">Chi siamo</router-link>
                </li>
              </ul>
            </div>
        </div>
      </nav>
      <div class="container text-help" style="">
          <h1>Chi siamo</h1>
      </div>
      
    </div>
    <div class="container form">
          <div class="chisiamo">
          <!-- #######  YAY, I AM THE SOURCE EDITOR! #########-->
            <p class="p1">GLOBAL FERRY BOOKING SRL (GFB) &egrave; una agenzia raccomandataria marittima attiva nel settore dei servizi marittimi, delle spedizioni e della logistica.</p>
            <p class="p1">Fondata nel 2020 la GFB &egrave; un'organizzazione privata che ha sviluppato una concreta esperienza nel settore dei servizi di assistenza ai broker ed agli armatori per acquisire traffici commerciali e passeggeri. Presente in Italia, nel Porto di Brindisi, GFB tende a favorire lo spostamento commerciale di merci e di passeggeri, anche sotto l&rsquo;aspetto ludico-ricreativo tra nazioni transfrontaliere e mediterranee.</p>
            <p class="p1">L&rsquo;obiettivo della societ&agrave; &egrave; quello di offrire un servizio globale, economico ed efficiente, di assistenza tecnico-logistica necessario per lo svolgersi delle operazioni di imbarco e sbarco di merci, mezzi e passeggeri che intendono partire dai porti di Brindisi, Ancona, Civitavecchia, Barcelona, Patrasso, Igoumenitsa, Ravenna, Catania, Valona, Durazzo, Arbatax, Cagliari, Corfu&rsquo;, Livorno, Napoli, Olbia, Palermo, P.Torres, Salerno, Savona, Tanger Med, Tunis.</p>
            <p class="p1">La GFB si &egrave; dotata di una struttura idonea ad offrire tutti i confort e gli agi ai viaggiatori, grazie al posizionamento strategico della sede legale/operativa <strong>in Brindisi Zona Industriale alla c/da Piccoli n. 8 , </strong>di circa 6000mq, distante circa 500mt dal Porto, avente area di sosta privata, completamente attrezzate ed automatizzata, con offerta di servizi di ristoro, utili agli operatori commerciali e alle famiglie che affrontano lunghi tragitti, a mezzo di distributori automatici, con docce, lavanderia automatizzata, Wi-Fi, minibus per gli spostamenti da aeroporto, stazione ferroviaria, e parcheggio per camper.</p>
            <p class="p1">La societ&agrave; ha anche una efficiente sede commerciale ed amministrativa situata nel Terminal del Porto di Brindisi nel quale vengono svolte tutte quelle pratiche necessarie a perfezionare gli arrivi e le partenze dei viaggiatori e a monitorare, con un programma applicativo appositamente studiato, tutte le prenotazioni e l&rsquo;emissione di biglietteria marittima.</p>
            <p class="p1">GFB cura, attraverso lo stesso software, ogni fase dei viaggi di piacere e/o di lavoro riuscendo a canalizzare velocemente le prenotazioni presso le primarie compagnie di navigazione con scalo nei molteplici porti del mediterraneo.</p>
          </div>
    </div>
  </div>

</template>

<style scoped>
.text-help{
margin-top:10px;
text-align:center;
color: white;
font-size: 20px;
}
.chisiamo{
    background-color: white;
    width: 100%;
    text-align: center;
    color: black;
}
@media (max-width: 767px)
    {
        .booking-form{
            width: 100%;
        }
    }
.form{
    margin-top: 10%;
    margin-bottom: 10%;
}
</style>

<script>

import ServizioApi from '@/servizi/ServizioApi.js'

export default {
    data() {
        return {
            inviato:false,
            contact_form:{
                nome:'',
                cognome:'',
                email:'',
                msg:''
            }
        }
    },
    methods:{
        async invia_messaggio(e)
        {
            e.preventDefault();

            var risp="";

            await ServizioApi.richiestaContatto(this.contact_form)
            .then(risposta=>(risp=risposta.data))
            

            if(risp)
            {
                this.inviato=true;
            }

            
        }
    }
}
</script>